.them-message {
    background-color: lightgray;
    color: black;
    border-radius: 8px 8px 8px 0px;
}

.you-message {
    text-align: left;
    background-color: var(--orange);
    color: white;
    border-radius: 8px 8px 0px 8px;
}

.them-message-deleted {
    border-radius: 8px 8px 8px 0px;
    color: grey;
    border-width: 1px;
    border-color: lightgray;
    border-style: solid;
}

.you-message-deleted {
    text-align: left;
    border-radius: 8px 8px 0px 8px;
    color: grey;
    border-width: 1px;
    border-color: lightgray;
    border-style: solid;
}

.message {
    font-size: 14px;
    padding-right: 60px;
}

.content-deleted {
    opacity: 0.5;
}

.message-overlay {
    visibility: hidden;
    position: absolute;
    top: 4px;
    right: 4px;
    pointer-events: none;
}

.date {
    font-size: 10px;
}

.deleted-date {
    font-size: 10px;
    color: red;
}

.messages-container {
    max-height: 70vh;
    overflow-y: auto;
}

.conversation-tab {
    position: relative;
    border-radius: 4px;
    border-width: 1px;
    border-color: lightgray;
    border-style: solid;
}
.conversation-tab-selected {
    position: relative;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: var(--orange);
}

.you-message:hover > .message-overlay, .you-message-deleted:hover > .message-overlay {
    visibility: visible;
}

.them-message:hover > .message-overlay, .them-message-deleted:hover > .message-overlay {
    visibility: visible;
}

.conversation-tab:hover > .message-overlay, .conversation-tab-selected:hover > .message-overlay {
    visibility: visible;
}

.message-btn {
    pointer-events: all;
    cursor: pointer;
}

.message-btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 18px;
    height: 18px;
}

.message-btn-delete .message-btn-icon {
    background-color: white;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    color: grey;
}

.message-btn-delete:hover .message-btn-icon {
    background-color: rgba(241, 241, 241);
}

.message-btn-restore {
    color: var(--orange);
    font-size: 12px;
}

.message-btn-restore .message-btn-icon {
    background-color: var(--orange);
    border-color: var(--orange);
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    color: white;
}