.dropdown-select {
  .dropdown-toggle {
    border: none;
    height: 38px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: $bg-topbar-search !important;
    box-shadow: none !important;
    outline: none !important;
    color: $gray-900 !important;
    text-align: left;
    width: 280px;

    &:hover, &:active {
      border: none;
      background-color: $bg-topbar-search !important;
      box-shadow: none !important;
      outline: none !important;
      color: $gray-900;
    }
  }

  .dropdown-select--header {
    color: $gray-600;
  }

  .dropdown-select--content {
    width: 278px;
  }

  .dropdown-select--item {
    font-size: 16px;
  }
}

.react-datepicker--time-box {
  width: 130px !important;
  min-width: 130px !important;
}

.react-datepicker__header--time {
  margin-left: -26px;
}

.react-datepicker--time-only {
  width: 130px !important;
  min-width: 130px !important;
}

.react-datepicker--time-list {
  width: 130px !important;
  min-width: 130px !important;
}

.react-datepicker__time-list-item {
  margin-left: -15px;
}