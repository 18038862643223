.hover-item-active:hover {
  background-color: rgba(215, 239, 246, .35);
}

.link-tile {
  display: block;
  color: unset;
  user-select: none;
}

.link-tile:hover {
  color: unset;
}

.link-open-icon {
  font-size: 1.2rem;
  cursor: pointer;
  color: unset;
}

.link-open-icon:hover {
  color: $orange;
}

.link-text-dark {
  color: unset;
}

.link-text-dark:hover {
  color: $gray-700;
}