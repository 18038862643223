.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 50px auto 15px auto;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.brand-orange {
  color: #FF9856
}

.brand-red {
  color: #DC584F
}

/* Hack that fixes the input width on Chrome; whilst still looking okay in Safari */
.react-datepicker-time__input { width: 120px !important; }

.sm-pill {
  letter-spacing: 3px !important;
  text-indent: 3px; /* Compensate for the fact that letter-spacing is added to the right of each letter */
  padding-top: 6px !important;
  padding-bottom: 4px !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.cursor-pointer{
  cursor: pointer;
}

/* Tab color overrides */

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  background-color: #fafbfe !important;
}

.nav-tabs .nav-link.disabled {
  color: lightgray !important;
}

.nav-tabs .nav-link {
  color: #495057 !important;
}

/* Hyper sets position: relative, site wide, which breaks assumptions in react-boostrap. Set it back here. */
html {
  position: initial !important;
}
