/* Styles for AdminNoteTile component */

.admin-note-tile .text-main {
  font-size: 18px;
}

.admin-note-tile .text-author {
  font-size: 18px;
  font-weight: bold;
}

.admin-note-tile .text-date {
  font-size: 15px;
}