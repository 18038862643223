
/* override Top level styles */

.side-nav > .mm-active > a > span {
    color: #98a6ad;
}

.side-nav > .mm-active.text-white > a > span {
    color: white;
}

/* override sub level styles, including white on hover */

.side-nav-item > .side-nav-link-ref.side-sub-nav-link> span {
    color: #98a6ad;
}

.side-nav-item > .side-nav-link-ref.side-sub-nav-link.text-white> span {
    color: white;
}

.side-nav-item > .side-nav-link-ref.side-sub-nav-link> span:hover {
    color: white;
}

.icon-inactive {
    color: #98a6ad;
}
