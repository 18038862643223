/*
Template Name: Hyper - React Js Admin Dashboard Template
Version: 2.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "config/saas/variables";
@import "config/saas/custom-variables";
@import "./node_modules/bootstrap/scss/bootstrap";


// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/right-sidebar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
// horizontal nav
@import "custom/structure/horizontal-nav";


//Fonts
@import "custom/fonts/nunito";


//Components
@import "custom/components/mixins";
@import "custom/components/accordions";
@import "custom/components/avatar";
@import "custom/components/breadcrumb";
@import "custom/components/buttons";
@import "custom/components/badge";
@import "custom/components/card";
@import "custom/components/dropdown";
@import "custom/components/forms";
@import "custom/components/modal";
@import "custom/components/nav";
@import "custom/components/pagination";
@import "custom/components/popover";
@import "custom/components/print";
@import "custom/components/progress";
@import "custom/components/reboot";
@import "custom/components/ribbons";
@import "custom/components/switch";
@import "custom/components/tables";
@import "custom/components/type";
@import "custom/components/utilities";
@import "custom/components/widgets";
@import "custom/components/social";
@import "custom/components/steps";
@import "custom/components/preloader";
@import "custom/components/hero";


//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/faq";
@import "custom/pages/maintenance";
@import "custom/pages/tasks";
@import "custom/pages/email";
@import "custom/pages/timeline";


// Vendors
@import "./node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min";


// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/britechart";
@import "custom/plugins/calendar";
@import "custom/plugins/chartjs";
@import "custom/plugins/datatable";
@import "custom/plugins/datepicker";
@import "custom/plugins/draft-wysiwyg";
@import "custom/plugins/dropzone";
@import "custom/plugins/maps";
@import "custom/plugins/metisMenu";
@import "custom/plugins/rangeslider";
@import "custom/plugins/react-select";
@import "custom/plugins/slimscroll";
@import "custom/plugins/simplemde";
@import "custom/plugins/typehead";
@import "custom/plugins/frappe-gantt";

// Custom SM things
@import "custom-sm/dropdown-select";
@import "custom-sm/generic";

/* Theme overrides */
.text-danger {
  color: #DC584F !important
}

.btn-danger {
  background-color: #DC584F !important;
  border-color: #DC584F !important;
}

.text-warning {
  color: #EAC645 !important
}