.overlay-trigger h3 {
  font-weight: 500 !important;
  font-size: 24px;
}

.overlay-trigger p.dropdown-toggle {
  transform: translateX(-10px);
  padding-top: 5px;
  font-weight: 400 !important;
}

.overlay-trigger p.dropdown-toggle:hover {
  opacity: 0.75;
}

.overlay-trigger p.dropdown-toggle::after {
  display: none;
}

.trigger-caret {
  position: absolute;
  margin-left: 76px;
  margin-top: -33px;
}

.popover#user-menu {
  left: 12px !important;
  top: 7px !important;
}